// extracted by mini-css-extract-plugin
export var aniSpin = "J_d";
export var back__link = "J_bL";
export var content = "J_t";
export var el = "J_b";
export var headOffset = "J_bx";
export var heading = "J_F";
export var icon = "J_V";
export var icon__container = "J_by";
export var icon__inner = "J_bB";
export var icon__sizer = "J_bz";
export var image = "J_D";
export var image__bg = "J_bC";
export var image__inner = "J_bD";
export var image__sizer = "J_bF";
export var intro = "J_bK";
export var text = "J_bg";
export var text__main = "J_bH";
export var text__main__heading = "J_bJ";
export var text__side = "J_bG";
export var thumbs = "J_k";
export var thumbs__item = "J_bM";