const rotation = -7

const animations = {
  imageBg: {
    hidden: {
      scaleY: 0,
      rotate: 0,
    },
    visible: {
      scaleY: 1,
      rotate: rotation,
      transition: {
        delay: 0.75,
        duration: 1.25,
        ease: 'anticipate',
      },
    },
  },
  image: {
    hidden: {
      scale: 0,
      x: -20,
      y: -200,
      rotate: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      x: 0,
      y: 0,
      opacity: 1,
      rotate: rotation,
      transition: {
        delay: 0.75,
        duration: 1.25,
        ease: 'anticipate',
      },
    },
  },
  textSide: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.7,
        duration: 0.9,
        ease: 'anticipate',
      },
    },
  },
  textMain: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.9,
        ease: 'anticipate',
      },
    },
  },
}

export default animations
