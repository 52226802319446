import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { get } from '@/utils/get'
import { Link } from '@/components/Link'
import { Theme } from '@/components/Theme'
import { Container } from '@/components/Container'
import { Img } from '@/components/Img'
import { RichText } from '@/components/RichText'
import { SectionRouter } from '@/components/SectionRouter'
import { Illustration } from '@/components/Illustration'

import { Image } from '@/sections/Image'
import { SmallImage } from '@/sections/SmallImage'
import { BodyText } from '@/sections/BodyText'
import { Hero } from '@/sections/Hero'
import { Reviews } from '@/sections/Reviews'
import { PageLinks } from '@/sections/PageLinks'
import { Video } from '@/sections/Video'

import * as styles from './Page.styles.scss'
import animations from './Page.animations'

const Page = ({
  title,
  illustration,
  intro,
  image,
  content,
  sections,
  parent,
  theme,
}) => {
  const hasParent = get(parent, 'path')
  let renderHeader = null
  if (image?.src) {
    renderHeader = (
      <div className={styles.image}>
        <motion.div
          className={styles.image__bg}
          variants={animations.imageBg}
        />
        <Container>
          <motion.div
            className={styles.image__inner}
            variants={animations.image}
          >
            <div className={styles.image__sizer} />
            <Img {...image} objectFit="cover" isFull />
          </motion.div>
        </Container>
      </div>
    )
  } else if (illustration !== 'none') {
    renderHeader = (
      <Container>
        <div className={styles.icon}>
          <div className={styles.icon__container}>
            <div className={styles.icon__sizer} />
            <div className={styles.icon__inner}>
              <Illustration id={illustration} />
            </div>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Theme className={styles.el} theme={theme}>
      <motion.div initial="hidden" animate="visible">
        <div className={!!renderHeader ? null : styles.headOffset}>
          {renderHeader}
          <Container>
            <div className={styles.text}>
              <motion.div
                className={styles.text__side}
                variants={animations.textSide}
              >
                {hasParent ? (
                  <div>
                    <Link to={parent.path} className={styles.back__link}>
                      Back to {parent.title}
                    </Link>
                  </div>
                ) : (
                  <h2 className={styles.heading}>{title}</h2>
                )}
              </motion.div>
              <motion.div
                className={styles.text__main}
                variants={animations.textMain}
              >
                {hasParent ? (
                  <>
                    <h2 className={styles.heading}>{title}</h2>
                    <div className={styles.intro}>
                      <RichText>{intro}</RichText>
                    </div>
                  </>
                ) : (
                  <div className={styles.heading}>
                    <RichText>{intro}</RichText>
                  </div>
                )}
                <div className={styles.content}>
                  <RichText>{content}</RichText>
                </div>
              </motion.div>
            </div>
          </Container>
        </div>
        <SectionRouter
          sections={sections}
          types={{
            hero: Hero,
            body_text: BodyText,
            big_image: Image,
            small_image: SmallImage,
            reviews: Reviews,
            page_links: PageLinks,
            video: Video,
          }}
        />
      </motion.div>
    </Theme>
  )
}

Page.defaultProps = {
  title: undefined,
  intro: undefined,
  content: undefined,
  illustration: undefined,
  image: {},
  sections: [],
  parent: {},
  theme: undefined,
}

Page.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.node,
  content: PropTypes.node,
  illustration: PropTypes.string,
  image: PropTypes.object,
  sections: PropTypes.arrayOf(PropTypes.object),
  parent: PropTypes.object,
  theme: PropTypes.string,
}

export default Page
