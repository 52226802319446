import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { get } from '@/utils/get'
import { getSlug } from '@/utils/getSlug'
import { getImage } from '@/prismic/utils/getImage'
import { getRichText } from '@/prismic/utils/getRichText'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import Page from '@/templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicFlexible } = data
  const parentPath = get(prismicFlexible, 'data.parent.document.uid')
  const parentTitle = get(prismicFlexible, 'data.parent.document.data.title')
  const parent = parentPath
    ? { path: `/${parentPath}`, title: parentTitle }
    : undefined
  const theme = getSlug(prismicFlexible.data.theme)
  const sections = sectionSerializer(prismicFlexible.data.body, theme)

  return (
    <Page
      title={get(prismicFlexible, 'data.title')}
      image={getImage(prismicFlexible, 'data.image', 'fluid')}
      illustration={getSlug(prismicFlexible.data.illustration)}
      intro={getRichText(prismicFlexible, 'data.intro.raw')}
      content={getRichText(prismicFlexible, 'data.content.raw')}
      parent={parent}
      sections={sections}
      theme={theme}
    />
  )
}

PagePrismic.defaultProps = {
  data: {},
  pageContext: {},
}

PagePrismic.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withPreview(PagePrismic)

export const pageQuery = graphql`
  query FlexibleByid($id: String!) {
    prismicFlexible(id: { eq: $id }) {
      uid
      id
      type
      data {
        illustration
        theme
        title
        parent {
          document {
            __typename
            ... on PrismicFlexible {
              uid
              data {
                title
              }
            }
          }
        }
        intro {
          raw
        }
        content {
          raw
        }
        image {
          fluid(maxWidth: 2500) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicFlexibleBodyBigImage {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 2500) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                raw
              }
            }
          }
          ... on PrismicFlexibleBodySmallImage {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyBodyText {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyReviews {
            id
            slice_type
            items {
              quote {
                raw
              }
              stars
              source {
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyPageLinks {
            id
            slice_type
            items {
              page_link {
                document {
                  ... on PrismicFlexible {
                    id
                    type
                    uid
                    data {
                      parent {
                        type
                        uid
                      }
                      title
                      image {
                        fluid(maxWidth: 500) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                        url
                        alt
                        dimensions {
                          width
                          height
                        }
                      }
                      theme
                    }
                  }
                }
              }
            }
          }
          ... on PrismicFlexibleBodyVideo {
            id
            slice_type
            primary {
              url
              aspect_ratio
            }
          }
        }
      }
    }
  }
`
